const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 行程单打印详情
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/manage/itineraryOrder/findItinerary',
    urlSuffix:'/buyer/dom/manage/itinerary/print/detail',
    data:data
  }

  return __request(pParameter)
}
export default recordCount

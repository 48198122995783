//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import findItinerary from "@/lib/data-service/flight/flight_domestic_buyer_manage_itinerarOrder_findItinerary";
import pdfParam from "@/lib/data-service/flight/flight_domestic_buyer_manage_itinerarOrder_pdfParam";
import baseUrl from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import { resolve } from "q";
export default {
  data() {
    var ticketNumberVerify = (rule, value, callback) => {
      const rsg = /(^([\d-]{13,14})*?)$/;
      if (value == "") {
        callback(new Error("请输入电子客票号"));
      } else if (!rsg.test(value)) {
        callback(
          new Error(
            "电子客票号输入有误! 票号的输入举例如下：“9992404665652” “999-2404665652”"
          )
        );
      } else {
        if (value.length >= 13) {
          callback();
        } else {
          callback(
            new Error(
              "电子客票号输入有误！票号的输入举例如下：“9992404665652” “999-2404665652”"
            )
          );
        }
      }
    };
    return {
      formData: {
        ticketNumber: ""
      },
      detail: {
        airportTax: "",
        cardNumber: "",
        fillDate: "",
        fillUnit: "",
        insurance: "",
        oilTax: "",
        otherTax: "",
        promptMessage: "",
        psgName: "",
        remark: "",
        salesUnitCode: "",
        ticketAmount: "",
        ticketNumber: "",
        totalAmount: "",
        verificationCode: "",
        newSegmentList: []
      },
      show: true,
      airLineList: [],
      rules: {
        ticketNumber: [
          { validator: ticketNumberVerify, required: true, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getDetail(val) {
      let _this = this;
      let statu = true;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          findItinerary({ ticketNumber: val }).then(res => {
            if (res.result) {
              this.detail = getList(res.result);
              delete this.detail.segmentGo;
              delete this.detail.segmentBack;
            }
            if (res.hasData == 2) {
              this.show = false;
            } else {
              this.show = true;
            }
          });
        } else {
          return false;
        }
      });

      function getList(params) {
        // params.segmentList.map(item => {
        let starFlight = params.segmentGo;
        let endFlight = params.segmentBack;
        let arr = [
          {
            airlineName: starFlight.airlineName,
            cabin: starFlight.cabin,
            cityName: starFlight.cityName,
            date: starFlight.date,
            deadlineDate: starFlight.deadlineDate,
            effectiveDate: starFlight.effectiveDate,
            flightNo: starFlight.flightNo,
            luggage: starFlight.luggage,
            segmentSerial: starFlight.segmentSerial,
            segmentType: starFlight.segmentType,
            ticketType: starFlight.ticketType,
            time: starFlight.time,
            statu: 0
          },
          {
            airlineName: endFlight.airlineName,
            cabin: endFlight.cabin,
            cityName: endFlight.cityName,
            date: endFlight.date,
            deadlineDate: endFlight.deadlineDate,
            effectiveDate: endFlight.effectiveDate,
            flightNo: endFlight.flightNo,
            luggage: endFlight.luggage,
            segmentSerial: endFlight.segmentSerial,
            segmentType: endFlight.segmentType,
            ticketType: endFlight.ticketType,
            time: endFlight.time,
            statu: 1
          }
        ];
        arr = [...arr];
        params.newSegmentList = arr;
        // });
        return params;
      }
    },
    saveTicket() {
      let list = this.detail;
      // if(!list.psgName)return
      // if(!list.cardNumber)return
      // if(!list.ticketNumber)return
      let data = JSON.parse(JSON.stringify(this.detail));
      let flightObj = {
        segmentBack: data.newSegmentList[1],
        segmentGo: data.newSegmentList[0]
      };
      data.segmentBack = flightObj.segmentBack;
      data.segmentGo = flightObj.segmentGo;
      for (var k in data) {
        if (
          k == "code" ||
          k == "hasData" ||
          k == "msg" ||
          k == "newSegmentList"
        ) {
          delete data[k];
        }
      }
      const environment = global.appConfig.environment;
      const urlPrefix = baseUrl({
        environment: environment,
        appKey: "flight"
      });
      pdfParam(data).then(res => {
        if (res.code == this.SUCCESS_CODE) {
          window.open(urlPrefix + res.html);
        }
      });
    },
    empty() {
      let list = this.detail;
      for (let x in list) {
        if (list[x] instanceof Array) {
          list[x].map(item => {
            for (let _i in item) {
              _i != "statu" ? (item[_i] = "") : "";
            }
          });
        } else {
          list[x] = "";
        }
      }
    },
    add(){
      const __this = this;
      __this.detail.total = Number(__this.detail.ticketAmount) + Number(__this.detail.airportTax) + Number(__this.detail.fuelTax);
    }
  },
  activated() {
    let arr;
    this.airLineList = arr = [
      {
        airlineName: "",
        cabinCode: "",
        cityName: "",
        date: "",
        deadlineDate: "",
        effectiveDate: "",
        flightNo: "",
        luggage: "",
        ticketType: "",
        time: "",
        statu: 1
      },
      {
        airlineName: "",
        cabinCode: "",
        cityName: "",
        date: "",
        deadlineDate: "",
        effectiveDate: "",
        flightNo: "",
        luggage: "",
        ticketType: "",
        time: "",
        statu: 1
      }
    ];
    let arr2 = JSON.parse(JSON.stringify(arr));
    arr2[0].statu = 0;
    this.detail.newSegmentList.length = 0;
    this.detail.newSegmentList.push(...arr2);
    let ticketNumber = this.$route.query.ticketNumber;
    ticketNumber
      ? ((this.formData.ticketNumber = ticketNumber),
        this.$nextTick(() => {
          this.getDetail(ticketNumber);
        }))
      : "";
  }
};
